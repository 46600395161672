import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { request } from '../../../../services/request';
import {
  ResolveStepRequest,
  ResolveStepResponse,
  UndoStepRequest,
} from '@wix/ambassador-online-programs-v3-participant/types';
import {
  resolveStep,
  undoStep,
} from '@wix/ambassador-online-programs-v3-participant/http';
import { ParticipantStep, ResolvedStep } from '../../../../types/v3Types';
import {
  quizSubmissionFromResolvedStep,
  resolutionFromResolvedStep,
} from '../../../ParticipantStepsDataProvider/toParticipantSteps';

export type ResolveStepData = Pick<
  ParticipantStep,
  'id' | 'quizSubmission' | 'transitions'
>;

export async function resolveStepV3(
  req: ResolveStepRequest,
  flowAPI: ControllerFlowAPI,
): Promise<ResolveStepResponse['resolvedStep']> {
  return (await request(flowAPI, resolveStep(req)))?.data?.resolvedStep;
}

function toResolvedStepData(resolvedStep: ResolvedStep) {
  return {
    id: resolvedStep.programStepId,
    transitions: resolutionFromResolvedStep(resolvedStep),
    quizSubmission: quizSubmissionFromResolvedStep(resolvedStep),
  };
}

export async function resolveParticipantStepV3(
  req: ResolveStepRequest,
  flowAPI,
) {
  const resolvedStep = await resolveStepV3(req, flowAPI);
  const step: ResolveStepData = toResolvedStepData(resolvedStep);
  return { step };
}

export async function undoStepV3(
  req: UndoStepRequest,
  flowAPI: ControllerFlowAPI,
) {
  await request(flowAPI, undoStep(req));
  const step: ResolveStepData = toResolvedStepData({
    programStepId: req.stepId,
  });
  return { step };
}
